export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Sprunki Infection",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://html-classic.itch.zone/html/11700918/index.html",
    domain: "sprunkiinfection.fun",
    gaId: "G-3P3W9ND1L3",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
